<template>
  <main class="main_v8 messages">
    <h1>Wave7 Messages</h1>
  </main>
</template>



<style lang="scss" scoped>
  .messages{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
  }
  </style>